import { fetchVisitorIP } from "./ipServices.js";

export async function prepareForm() {
  const applicant_ip = document.getElementById("applicant_ip");
  const applicant_state = document.getElementById("applicant_state");
  const applicant_country = document.getElementById("applicant_country");
  if (!applicant_ip || !applicant_state || !applicant_country) {
    return;
  }

  const ipinfo = await fetchVisitorIP();
  if (ipinfo && ipinfo.ip) {
    applicant_ip.value = ipinfo.ip;
    applicant_state.value = ipinfo.region;
    applicant_country.value = ipinfo.country;
  }
}
